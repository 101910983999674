import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import asyncComponent from '../components/async'
import NavigationContainer from './navigation-container'

const AsyncLoginContainer = asyncComponent(() => import('./login-container'))
const AsyncDocsContainer = asyncComponent(() => import('./docs-container'))

const { bool, shape } = PropTypes

class MainContainer extends Component {
    static propTypes = {
        user: shape({
            isAuthed: bool.isRequired
        }).isRequired
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="main-container">
                {this.props.user.isAuthed ?
                    <Router>
                        <Fragment>
                            <NavigationContainer />
                            <Route
                                exact
                                path="/"
                                component={AsyncDocsContainer}
                            />
                        </Fragment>
                    </Router>
                    :
                    <AsyncLoginContainer />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(MainContainer)
