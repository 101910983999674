import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Icon } from 'semantic-ui-react'

import '../styles/navigation.css'

const { shape, func } = PropTypes

const Navigation = props => (
    <nav>
        <Menu pointing>
            <Menu.Item>
                <a href="https://docs-dev.jawbonehealth.com">
                    Dev
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="https://docs-stage.jawbonehealth.com">
                    Stage
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="https://docs.jawbonehealth.com">
                    Prod
                </a>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item className="user-menu">
                    <p className="user-name">{props.user.name}</p>
                    <span className="user-sign-out" onClick={props.unAuthUser}>
                        Sign Out
                        <Icon name="log out" className="sign-out-icon" />
                    </span>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    </nav>
)

Navigation.propTypes = {
    user: shape().isRequired,
    unAuthUser: func.isRequired
}

export default Navigation
