import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Navigation from '../components/navigation'
import { unAuthUser } from '../actions/user'


const { shape, func } = PropTypes

class NavigationContainer extends Component { // eslint-disable-line
    static propTypes = {
        user: shape({}).isRequired,
        dispatch: func.isRequired
    }

    constructor() {
        super()

        this.unAuthUser = this.unAuthUser.bind(this)
    }

    unAuthUser() {
        this.props.dispatch(unAuthUser())
    }

    render() { // eslint-disable-line
        return (
            <Navigation
                user={this.props.user}
                unAuthUser={this.unAuthUser}
            />
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(NavigationContainer)
